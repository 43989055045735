<template>
  <div id="app" :class="darkmode?'darkTheme':'lightTheme'">
    <div id="nav">
      <Navbar :links="sites" :actions="action" :show-profile-dialog="toggleProfileDialog" :darkmode="darkmode"
              :hide-profile-dialog="hideProfileDialog" :toggle-darkmode="toggleDarkMode"></Navbar>
    </div>
    <router-view id="view">

    </router-view>
  </div>
</template>
<script>
import Navbar from "./components/Navigation/Navbar";
import {mapActions, mapState} from "vuex";
export default {
  components: { Navbar},
  methods: {
    ...mapActions('account', ['logout','getUserData']),
    toggleProfileDialog() {
      this.showProfileDialog = !this.showProfileDialog
    },
    hideProfileDialog() {
        this.showProfileDialog = false;
    },
    toggleDarkMode(){
      this.darkmode= !this.darkmode;
      document.body.classList.add(this.darkmode?"darkBG":"lightBG")
      document.body.classList.remove(this.darkmode?"lightBG":"darkBG")
      localStorage.setItem('darkmode',this.darkmode)
    }
  },
  computed:{
    ...mapState('account',['user'])
  },
  created() {
    this.getUserData();
    this.darkmode=localStorage.getItem('darkmode')
    if(this.darkmode===null) {
      this.darkmode = true;
      localStorage.setItem('darkmode', 'true')
      document.body.classList.add('darkTheme')
    }else{
      this.darkmode=this.darkmode=='true';
      document.body.classList.add(this.darkmode?"darkBG":"lightBG")
      document.body.classList.remove(this.darkmode?"lightBG":"darkBG")
    }
},
  data() {
    return {
      darkmode:true,
      showProfileDialog: false,
      sites: [
        /*{
          name: 'Über uns',
          linkName: 'about',
          path: '/about'
        }*/{
          name: 'Turnier',
          linkName: 'tournaments',
          path: '/tournaments',
        },
      ],
      action: [
        {
          name: 'Login',
          actionFunction: null,
          linkName: 'login',
          path: '/login',
          displayOnLogin: false

        },
        {
          name: 'Registrieren',
          actionFunction: null,
          linkName: 'register',
          path: '/register',
          displayOnLogin: false
        },
        {
          name: 'Profil',
          actionFunction: null,
          linkName: 'profile',
          path: '/profile',
          displayOnLogin: true

        },
        {
          name: 'Teilnahmen',
          actionFunction: null,
          linkName: 'participations',
          path: '/profile/participations',
          displayOnLogin: true
        },
        {
          name: 'Admin',
          actionFunction: null,
          linkName: 'admin',
          path: '/admin',
          displayOnLogin: true,
          displayOnAdmin: true,
        },
        {
          name: 'Logout',
          actionFunction:()=>{
            this.logout(
            );
          },
          path:null,
          displayOnLogin: true
        }
      ]
    }
  }
}
</script>
<style lang="scss">
@import "styles/themes.scss";
@import "styles/style.scss";


*{
  transition: 0.25s background-color, color;
  text-decoration: none;
}
.backgroundDialog {
  z-index: 89;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;

}

.navigation-enter-active, .navigation-leave-active {
  transition: all 0.25s ease;
}

.navigation-leave-to {
  opacity: 0;
}

.navigation-enter-from {
  opacity: 0;
}

#app {
  position: relative;
  height: 100%;
  @include theme(){
    background-color: theme-get('background-color-2');
  }
  text-align: center;
}
a{
  @include theme(){
    color: theme-get('primary-color');
  }
  &:hover{
    text-decoration: underline;
  }
  &:visited{
    @include theme(){
      color: theme-get('primary-color');
    }
  }
}
#view{
  padding-top:80px;
  height: 100%;
  position: relative;
}




  #nav {
    z-index: 100;
    position: relative;
    width: 100%;
  }

</style>
