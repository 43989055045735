<template>
  <div class="navbar">
    <!--NavBar-->
      <router-link to="/">
        <img src="../../assets/illustrations/Controller.svg">
      </router-link>

    <div class="nav">
      <div class="links" v-if="!isSmall">
        <router-link :to="link.path" v-for="link in links" @click="hideProfileDialog" :key="link.name" class="navitem"
                     :class="{'selected':matchRoute(link.linkName)}">
          <div>
            {{ link.name }}
          </div>
        </router-link>
        <a href="https://leaderboard.kaindorf-games.at" class="navitem">
          <div>
            Leaderboard
          </div>
        </a>
      </div>
      <div class="actions" v-if="!isSmall">
        <div v-if="user===null">
          <div v-for="action in actions.filter(value => !value.displayOnLogin)" :key="action.name">
            <router-link v-if="action.path!=null" class="navitem" :to="action.path"
                         :class="{'selected':matchRoute(action.linkName)}" @click="hideProfileDialog">
              <div>
                {{ action.name }}
              </div>
            </router-link>
            <div v-else class="navitem" @click="action.actionFunction">
              {{ action.name }}
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="user.userdata?.role?.role_id===1">
            <div v-for="action in actions.filter(value => value.displayOnLogin&&value.displayOnAdmin)" :key="action.name">
              <router-link v-if="action.path!=null" class="navitem" :to="action.path" :class="{'selected':matchRoute(action.linkName)}" @click="hideProfileDialog">
                <div>
                  {{ action.name }}
                </div>
              </router-link>
            </div>
          </div>
          <div v-for="action in actions.filter(value => value.displayOnLogin&&!value.displayOnAdmin)" :key="action.name">
            <router-link v-if="action.path!=null" class="navitem" :to="action.path"
                         :class="{'selected':matchRoute(action.linkName)}" @click="hideProfileDialog">
              <div>
                {{ action.name }}
              </div>
            </router-link>
            <div v-else class="navitem" @click="action.actionFunction">
              {{ action.name }}
            </div>
          </div>

        </div>
        <div class="material-icons" id="darkmodeBtn" @click="this.toggleDarkmode">
          {{ darkmode ?   'light_mode':'dark_mode' }}
        </div>
      </div>
      <div v-if="isSmall" class="material-icons menubutton" @click="showNavDrawer=!showNavDrawer">
        menu
      </div>
    </div>


    <!-- Navdrawer-->
    <transition name="navdrawer-background">
      <div class="navdrawerContainer" v-if="showNavDrawer">
        <transition name="navdrawer">
          <div class="navdrawer">
            <div class="material-icons dark"  @click="this.toggleDarkmode">  {{ darkmode ?  'light_mode':'dark_mode'  }}</div>
            <div class="material-icons close" @click="showNavDrawer=!showNavDrawer">clear</div>
            <div id="drawerLinks">
              <router-link :to="link.path" v-for="link in links" :key="link.name" class="drawerItem"
                           @click="showNavDrawer=!showNavDrawer">
                <div :class="{'selected':matchRoute(link.linkName)}">
                  {{ link.name }}
                </div>
              </router-link>
            <a href="https://leaderboard.kaindorf-games.at" class="drawerItem">
                <div>
                  Leaderboard
                </div>
              </a>
            </div>


            <div id="drawerActions">
              <div v-if="user===null">
                <div v-for="action in actions.filter(value => !value.displayOnLogin)" :key="action.name">
                  <router-link :to="action.path" class="drawerItem"
                               @click="()=>{showNavDrawer=!showNavDrawer; hideProfileDialog}">
                    <div :class="{'selected':matchRoute(action.linkName)}">
                      {{ action.name }}
                    </div>
                  </router-link>
                </div>
              </div>
              <div v-else class="profile-section">
                <div>
                  <h3>{{ this.user?.userdata.user_name }}</h3>
                  <div class="material-icons drawerProfile"
                       @click="()=>{$router.push('/profile'); showNavDrawer=false}">
                    person
                  </div>
                </div>
                <PlainButton :button-function="()=>{logout(); this.showNavDrawer=false}">Logout</PlainButton>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import PlainButton from "@/components/Buttons/PlainButton";

export default {
  name: "Navbar",
  components: {PlainButton},
  data: function () {
    return {
      isSmall: false,
      showNavDrawer: false
    }
  },
  watch: {
    showNavDrawer(isOpen) {
      if (isOpen) {
        document.documentElement.style.overflow = "hidden";
      } else {
        document.documentElement.style.overflow = "auto";
      }
    }
  },
  props: {
    hideProfileDialog: Function,
    showProfileDialog: Function,
    links: Array,
    actions: Array,
    darkmode: Boolean,
    toggleDarkmode: Function
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    this.onResize();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapActions('account', ['logout']),
    matchRoute(link){
      return this.$route.meta?.pageid===link
    },
    onResize() {
      if (window.innerWidth < 1024 && this.isSmall === false) {
        this.isSmall = true;
        this.hideProfileDialog();
      } else if (window.innerWidth > 1024 && this.isSmall === true) {
        this.isSmall = false;
        this.showNavDrawer = false;
      }
    },

  },
  computed: {
    ...mapState('account', ['user']),
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/themes";

img{
  max-height: 64px;
  padding: 8px;
  margin-right: 24px;
}

#darkmodeBtn {
  color: white;
  padding: 8px;
  height: min-content;
  margin: 0 4px;
  cursor: pointer;
  border-radius: 100%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.navdrawer-background-enter-active, .navdrawer-background-leave-active {
  transition: 0.25s;
}
.navdrawer-background-enter-from, .navdrawer-background-leave-to {
  opacity: 0;
}
.navdrawer-enter-active, .navdrawer-leave-active {
  transition: 0.25s;
}
.navdrawer-enter-from, .navdrawer-leave-to {
  transform: translateX(100%);
}

.navbar {
  z-index: 100;
  box-sizing: border-box;
  padding: 0 144px;
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;

  @include theme() {
    box-shadow: theme-get('shadow-primary');
    background-color: theme-get('navbar');
  }
  div {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .navitem {
    outline: none;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-decoration: none;
    color: white;
    box-sizing: border-box;
    transition: background-color 0.25s;

    &:after {

      content: '';
      width: 80%;
      height: 4px;
      transform: scaleX(0);
      border-radius: 4px;
      background-color: white;
      position: absolute;
      transition: transform 0.25s;
      bottom: 4px;
    }

    &.selected {
      @include theme() {
        color: theme-get('navbar-selection');
        background-color: theme-get('navbar-selection-background');
      }

      &:after {
        transform: scaleX(1);
        @include theme() {
          background-color: theme-get('navbar-selection');
        }

      }
    }

    &:hover {

      @include theme() {

        background-color: theme-get('navbar-hover');
      }

    }

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 24px;
  }
}

.navdrawerContainer {
  text-decoration: none;
  @include theme() {
    color: theme-get('text-1')
  }
  overflow: hidden;
  left: 0;
  top: 0;
  position: fixed;
  backdrop-filter: blur(20px);
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;

  .navdrawer {
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
    position: fixed;
    width: 80%;
    height: 100%;
    top: 0;
    right: 0;
    @include theme() {
      background-color: theme-get('background-color-1');
    }
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .drawerItem {
      width: 100%;
      padding: 16px;
      text-decoration: none;
      @include theme() {
        color: theme-get('text-1');
      }

      div {
        width: 100%;
        position: relative;
        border-radius: 8px;
        padding: 16px;

        &.selected {
          font-weight: 600;
          @include theme() {
            color: theme-get('primary-color');
            background-color: theme-get('primary-background');
          }

          &::after {
            transition: 0.25s;
            box-sizing: border-box;
            content: "";
            position: absolute;
            right: 16px;
            padding: 6px;
            height: auto;
            border-radius: 6px;
            @include theme() {
              background-color: theme-get('primary-color');
            }
          }
        }
      }

      &:hover {
        @include theme() {
          background-color: theme-get('navbar-hover');
        }
      }

    }
  }

  .dark{
    cursor: pointer;
    transition: 0.25s;
    padding: 8px;
    font-size: 24px;
    @include theme() {
      color: theme-get('primary-color');
      background-color: theme-get('primary-background');
    }
    border-radius: 32px;
    height: auto;
    position: absolute;
    left: 20px;
    top: 20px;

    &:hover {
      @include theme() {
        background-color: theme-get('primary-color');
      }
      color: #FFFFFF;
    }
  }
  .close{
    cursor: pointer;
    transition: 0.25s;
    padding: 8px;
    font-size: 24px;
    @include theme() {
      color: theme-get('primary-color');
      background-color: theme-get('primary-background');
    }
    border-radius: 32px;
    height: auto;
    position: absolute;
    right: 20px;
    top: 20px;

    &:hover {
      @include theme() {
        background-color: theme-get('primary-color');
      }
      color: #FFFFFF;
    }
  }
  #drawerLinks{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

  }
  #drawerActions {
    height: auto;
    display: flex;
    @include theme() {
      background-color: theme-get('background-color-2');
    }
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: auto;
    padding-bottom:32px;
    padding-top:16px;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      height: auto;


    }

    .profile-section {
      div {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        align-items: center;
      }

      gap: 16px;
      padding: 32px;

      .drawerProfile{
        cursor: pointer;
        padding: 8px;
        display: block;
        position: relative;
        max-width: min-content;
        @include theme() {
          color: theme-get('text-1');
          background-color: theme-get('navbar-profile-background');
        }
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 100%;

        &:hover {
          background-color: rgba(0, 0, 0, 0.20);

        }
      }
    }


  }


}


a {
  display: flex;
  border: none;
  text-decoration: none;
  &:active {
    border: none;
  }
}

.nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}



@media screen and (max-width: 1024px) {
  .navbar {
    padding: 0 16px;
  }
  img {
    padding: 0;
  }
  .nav {
    justify-content: flex-end;
  }
  .menubutton {
    cursor: pointer;
    color: white;
  }

}

@media screen and (max-width: 768px) {


}
</style>
