const state={
    type:null,
    message:null,

}
const actions={
    success({commit},message){
        commit('success',message);
    },
    error({commit},message){
        commit('error',message)
    },
    loading({commit}){
        commit('loading')
    },
    clear({commit}){
        commit('clear')
    }
}

const mutations={
    success(state,message){
        state.type='alert-success';
        state.message=message;
    },
    loading(state){
        state.type='alert-loading';
        state.message='';
    },
    error(state,message){
        state.type='alert-danger';
        state.message=message;
    },
    clear(state){
        state.type=null;
        state.message=null;
    }
}
export const alert={
    namespaced:true,
    state,
    actions,
    mutations
}
