import { createStore } from 'vuex'

import {alert} from './modules/alert.module'
import {account} from "./modules/account.module";

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    alert,
    account,
  }
})
