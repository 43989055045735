import {createRouter, createWebHistory} from 'vue-router'
import store from "../store/";

const routes = [
    {
        path: '/',
        name: 'home',
        meta:{
            pageid:'home'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),

    },
    /*{
        path: '/about',
        name: 'about',
        meta:{
            pageid:'about'
        },
        component: () => import(/!* webpackChunkName: "about" *!/ '../views/About.vue')
    },
    {
        path: '/legalnotice',
        name: 'legal',
        meta:{
          pageid:'legal'
        },
        component: () => import( '../views/LegalNotice.vue')
    },*/
    {
        path: '/register',
        name: 'register',
        meta:{
            pageid:'register'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/Registration/Register.vue'),
        beforeEnter: (to, from, next) => {
            if (store.state.account.user != null) {
                next('/')
            } else {
                next()
            }
        }
    },

    {
        path: '/register/success',
        name: 'register:success',
        meta:{
            pageid:'register'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/Registration/RegisterComplete.vue'),
        beforeEnter: (to, from, next) => {
            if (store.state.account.status.registered) {
                next()
            } else {
                next('/register')
            }
        }
    },
    {
        path: '/login',
        name: 'login',
        meta:{
            pageid:'login'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
        beforeEnter: (to, from, next) => {
            if (store.state.account.user != null) {
                next('/')
            } else {
                next()
            }
        }
    },
    {
        path: '/tournaments',
        name: 'tournaments',
        meta:{
            pageid:'tournaments'
        },
        component: () => import('../views/Tournaments.vue'),
        children: [
            {
                path: ':tournament_id',
                name: 'tournaments',
                component: () => import('../views/Tournaments.vue'),
            }
        ]
    },
    {
        path: '/team/join-direct',
        name: 'direct-join',
        meta:{
            pageid:'tournaments'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/Team/TeamDirectJoin.vue'),
        beforeEnter: (to, from, next) => {
            if (store.state.account.user === null) {
                next("/login")
            } else {
                if (store.state.account.user.userdata===undefined) {
                    store.dispatch("account/getUserData").then(
                      () => {
                          next()
                      }
                    );
                } else {
                    next()
                }
            }
        },
    },
    {
        path: '/team/:tournament_id',
        name: 'team',
        meta:{
            pageid:'tournaments'
        },
        component: () => import('../views/Team/Teams.vue'),
        beforeEnter: (to, from, next) => {
            if (store.state.account.user === null) {
                next("/login")
            } else {
                if (store.state.account.user.userdata===undefined) {
                    store.dispatch("account/getUserData").then(
                      () => {
                          next()
                      }
                    );
                } else {
                    next()
                }
            }
        }
    },
    {
        path:'/admin',
        name:'admin',
        meta:{
            pageid:'admin'
        },
        component:()=> import('../views/Admin/Admin'),
        children:[
            {
                path: '',
                redirect: '/admin/events',
                component: () => import( '../views/Admin/Tournament.vue')
            },
            {
                path: 'games',
                meta:{
                    subpage:'games'
                },
                component: () => import( '../views/Admin/Game.vue')
            },
            {
                path: 'events',
                meta:{
                    subpage:'events'
                },
                component: () => import( '../views/Admin/Events/AdminEvents.vue'),

            },

            {
                path: 'events/:event_id',
                meta:{
                    subpage:'events'
                },
                component: () => import('../views/Admin/Events/AdminEventDetail.vue')
            },
            {
                path: 'events/:event_id/addTournament',
                meta:{
                    subpage:'events'
                },
                component:()=>import('../views/Admin/Events/AddTournament.vue')
            },
            {
                path: 'events/:event_id/tournament/:tournament_id',
                meta:{
                    subpage:'events'
                },
                component: () => import('../views/Admin/Events/AdminTournament.vue')
            },
            {
                path: 'events/:event_id/tournament/:tournament_id/update/',
                meta:{
                    subpage:'events'
                },
                component: () => import('../views/Admin/Events/UpdateTournament.vue')
            },
            {
                path: 'events/:event_id/tournament/:tournament_id/team/:team_id',
                meta:{
                    subpage:'events'
                },
                component: () => import('../views/Admin/Events/AdminTeam.vue')
            }
        ],
        beforeEnter: (to, from, next) => {
            if (store.state.account.user === null) {
                next("/login")
            } else {
                if (store.state.account.user.userdata === undefined) {
                    store.dispatch("account/getUserData").then(
                        () => {
                            if (store.state.account.user.userdata?.role !== undefined && store.state.account.user.userdata?.role?.role_id === 1) {
                                next()
                            } else {
                                next('/')
                            }
                        }
                    )

                } else {
                    if (store.state.account.user.userdata?.role?.role_id === 1) {
                        next()
                    } else {
                        next('/')
                    }
                }
            }
        }
    },
    {
        path: '/profile',
        meta:{
            pageid:'profile'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/Profile/Profile.vue'),
        children: [
            {
                path: '',
                name: 'profile',
                component: () => import(/* webpackChunkName: "about" */ '../views/Profile/User.vue'),

            },
            {
                path: 'participations',
                name: 'participations',
                meta:{
                    pageid:'participations'
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/Profile/Participations.vue'),

            },
        ],
        beforeEnter: (to, from, next) => {
            if (store.state.account.user === null) {
                next("/login")
            } else {
                if (store.state.account.user.userdata === null) {
                    store.dispatch("account/getUserData").then(
                        () => {
                            next();
                        }
                    )
                } else {
                    next();
                }
            }
        }
    },
    {
        path: '/verification/mailSuccess',
        name: 'Mail Verification',
        component: () => import('../views/Verifications/MailVerification')
    },
    {
        path: '/verification/discordSuccess',
        name: 'DiscordVerification',
        component: () => import('../views/Verifications/DiscordVerification')
    },
    {
        path: '/verification/fail',
        name: 'VerificationFail',
        component: () => import('../views/Verifications/NotVerified')
    },

    {
        name:'Reset Password',
        path:'/resetpassword',
        component: () => import('../views/Password/PasswordResetRequest')
    },{
        name:'Enter New Password',
        path:'/newpassword',
        component: () => import('../views/Password/PasswordResetForm')
    },



    {
        path: '/:pathMatch(.*)*',
        component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior() {
        return {top: 0}
    },
    routes

})

router.beforeEach((to, from, next) => {
    store.dispatch("alert/clear").then(
        () => next()
    );

})

export default router
