import {service} from "./service";

export const userService = {
    login,
    logout,
    register,
    getUserData,
    update,
    resendVerification,
    sendPasswordResetRequest,
    sendNewPassword
}

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': "application/x-www-form-urlencoded"},
        body: new URLSearchParams({"grant_type": 'password', 'username': email, 'password': password})
    }
    return fetch(service.apiURL + '/user/login', requestOptions).then(response => service.handleResponse(response, false)).then(user => {
        if (user.access_token) {
            localStorage.setItem('user_token', JSON.stringify(user));
        }
        return user;
    })
}

function logout() {
    localStorage.removeItem('user_token')
    location.reload();
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(user)
    }
    return fetch(service.apiURL + '/user/register', requestOptions).then(response => service.handleResponse(response,false))
}

function update(user) {
    const request = {
        method: 'PATCH',
        headers: {
            'Authorization': service.getAuthorizationHeader(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    }
    return fetch(service.apiURL + '/user/me', request).then(response => service.handleResponse(response,true))
}

function getUserData() {
    const request = {
        method: 'GET',
        headers: {
            'Authorization': service.getAuthorizationHeader(),
        }
    }
    return fetch(service.apiURL + '/user/me', request).then(response => service.handleResponse(response, true).then(userresponse => {
        return userresponse;
    }))
}

function resendVerification(type) {
    const request = {
        method: 'GET',
        headers: {
            'Authorization': service.getAuthorizationHeader(),
        }
    }
    return fetch(service.apiURL + '/user/me/send_verification?' + 'vtype=' + type, request).then(
        response => service.handleResponse(response, true).then(
            isSent=>{
                service.setSuccess('Verification Message was sent')
                return isSent
            },
            error=>{
                service.setError(error)
            }
        )
    )

}
function sendPasswordResetRequest(credentials){
    return fetch(service.apiURL + '/user/me/reset_password?' + 'credentials=' + credentials).then(
        response => service.handleResponse(response, false).then(
            isSent=>{
                service.setSuccess('Password reset link was sent')
                return isSent
            },
            error=>{
                if(Array.isArray(error)){
                    service.setError("Either a wrong mail or discord name was entered")
                }else{
                    service.setError(error)
                }
            }
        )
    )
}

function sendNewPassword(prt,password){

    const request = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(password)
    }

    return fetch(service.apiURL + '/user/me/reset_password?' + 'prt=' + prt,request).then(
        response => service.handleResponse(response, false).then(
            isSent=>{
                service.setSuccess('Password was reset')
                return isSent
            },
            error=>{
                service.setError(error)
            }
        )
    )
}


