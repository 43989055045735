<template>
  <button id="solidButton"
          :class="[{'disabled':isDisabled,'small':isSmall, 'slim':isSlim, 'childScale':scaleByChild}, buttonType]"
          @click.prevent="buttonFunction()">
    <slot class="button-content"></slot>
  </button>
</template>

<script>
export default {
  name: "PlainButton",
  props: {
    isDisabled: Boolean,
    buttonFunction: Function,
    buttonType: {
      type: String,
      default: 'normal',
      validator: t => ['cancel', 'success', 'normal', 'fluid'].includes(t)
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    isSlim: {
      type: Boolean,
      default: false
    },
    scaleByChild: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/themes.scss";
.childScale {
  min-width: max-content;
  width: max-content !important;
}

.disabled {
  pointer-events: none;
  filter: grayscale(0.8);
}

.slim {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.small {
  padding: 12px 16px !important;
}

//Color Definitions
.success{
  @include theme() {
    background-color: theme-get('success');
  }
  color:white
}

.cancel{
  background-color: #D55353;
  color:white
}

.fluid{
  @include theme(){
    background-color: theme-get('primary-background');
    color: theme-get('primary-color')
  }
}
.normal{
  color:white;
  @include theme() {
    background-color: theme-get('primary-color');
    box-shadow: theme-get('shadow-1');
  }
}

#solidButton {
  box-sizing: border-box;
  transition: 0.1s;
  cursor: pointer;
  user-select: none;
  border: none;
  width: 100%;
  padding: 16px 64px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 1em;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }

  .button-content {
    width: auto;
    line-break: strict;
  }
}
</style>
