import {service} from "./service";

export const adminService = {
  fetchEvents,
  addTournament,
  addGame,
  addEvent,
  updateTournament,
  fetchGames,
  fetchEventById,
  fetchTournamentById,
  toggleTournament,
  toggleEvent,
  endTournament,
  moveTournamentToPhase,
  setTeamAbsence,
}


function addGame(game) {
  const request = {
    method: 'POST',
    headers: {
      'Authorization': service.getAuthorizationHeader(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(game)
  }
  return fetch(service.apiURL + '/game', request).then(response => service.handleResponse(response, true));
}

function addEvent(event) {
  const request = {
    method: 'POST',
    headers: {
      'Authorization': service.getAuthorizationHeader(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(event)
  }
  return fetch(service.apiURL + '/event', request).then(response => service.handleResponse(response, true));
}

function toggleEvent(id, isOpen) {
  const request = {
    method: 'PATCH',
    headers: {
      'Authorization': service.getAuthorizationHeader(),
    },
  }
  return fetch(service.apiURL + '/event/' + id + '/registration?state=' + (!isOpen ? 'open' : 'close'), request).then(response => service.handleResponse(response, true));
}

function toggleTournament(id, isOpen) {
  const request = {
    method: 'PATCH',
    headers: {
      'Authorization': service.getAuthorizationHeader(),
    },
  }
  return fetch(service.apiURL + '/tournament/' + id + '/registration?state=' + (!isOpen ? 'open' : 'close'), request).then(response => service.handleResponse(response, true));
}

function addTournament(tournament) {
  const request = {
    method: 'POST',
    headers: {
      'Authorization': service.getAuthorizationHeader(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(tournament)
  }
  return fetch(service.apiURL + '/tournament', request).then(response => service.handleResponse(response, true));
}

function updateTournament(id, tournament) {
  const request = {
    method: 'PATCH',
    headers: {
      'Authorization': service.getAuthorizationHeader(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(tournament)
  }
  return fetch(service.apiURL + '/tournament/' + id, request).then(response => service.handleResponse(response, true));
}


function fetchEvents() {
  service.setLoading();
  const request = {
    method: 'GET',
    headers: {
      'Authorization': service.getAuthorizationHeader(),
    }
  };
  return fetch(service.apiURL + '/event', request).then(
    response => service.handleResponse(response, true).then(
      event => {
        service.clearAlerts()
        return event
      },
      error => {
        service.setError(error)
      }
    )
  )
}

function fetchEventById(id) {
  service.setLoading();
  const request = {
    method: 'GET',
    headers: {
      'Authorization': service.getAuthorizationHeader(),
    }
  };
  return fetch(service.apiURL + '/event/' + id, request).then(
    response => service.handleResponse(response, true).then(
      event => {
        service.clearAlerts()
        return event
      },
      error => {
        service.setError(error)
      }
    )
  )
}





function fetchTournamentById(id) {
  service.setLoading();
  const request = {
    method: 'GET',
    headers: {
      'Authorization': service.getAuthorizationHeader(),
    }
  };
  return fetch(service.apiURL + '/tournament/' + id, request).then(
    response => service.handleResponse(response, true).then(
      tournament => {
        service.clearAlerts()
        return tournament
      },
      error => {
        service.setError(error)
      }
    )
  )
}

function fetchGames() {
  service.setLoading();
  const request = {
    method: 'GET',
  };
  return fetch(service.apiURL + '/game', request).then(
    response => service.handleResponse(response, true).then(
      game => {
        service.clearAlerts()
        return game
      },
      error => {
        service.setError(error)
      }
    )
  )
}

function moveTournamentToPhase(tournamentId, newPhase, currentPhase, datetime = null) {
  service.setLoading();

  let request = {
    method: 'PATCH',
    headers: {
      'Authorization': service.getAuthorizationHeader(),
      'Content-Type': 'application/json'
    },
  };

  let url = service.apiURL + '/tournament/' + tournamentId;
  if (newPhase === 1) {
    if (currentPhase === 2) {
      request["body"] = JSON.stringify({
        "team_registration_closed": datetime.toJSON() // Zeitabfrage
      });
    }
  } else if (newPhase === 2) {
    if (currentPhase === 1) {
      request["body"] = JSON.stringify({
        "team_registration_closed": new Date().toJSON() //now
      })
    } else if (currentPhase === 3) {
      url += "/player_participation_closed/set?team_registration_closed=" + datetime.toJSON(); // Zeitabfrage
    }
  } else if(newPhase === 3) {
    if (currentPhase === 2) {
      url += "/player_participation_closed/set"; // Unsets the phase
    } else if (currentPhase === 4) {
      request["body"] = JSON.stringify({
        "datetime": datetime.toJSON() // Zeitabfrage
      })
    }
  } else if(newPhase === 4) {
    if(currentPhase === 3) {
      request["body"] = JSON.stringify({
        "datetime": new Date().toJSON() // now
      })
    }
  }

  return fetch(url, request).then(
    response => service.handleResponse(response, true).then(
      () => {
        service.clearAlerts()
        return true;
      },
      error => {
        service.setError(error)
        return false;
      }
    )
  )
}

function endTournament(tournamentId, winner_id) {
  const request = {
    method: 'PATCH',
    headers: {
      'Authorization': service.getAuthorizationHeader()
    }
  };
  return fetch(service.apiURL + '/tournament/' + tournamentId + "/end?winner_id=" + winner_id, request).then(
    response => service.handleResponse(response, true).then(
      () => {
        service.clearAlerts()
        return true;
      },
      error => {
        service.setError(error)
        return false;
      }
    )
  )

}

function setTeamAbsence(team_id) {
  const request = {
    method: 'PATCH',
    headers: {
      'Authorization': service.getAuthorizationHeader()
    }
  };
  return fetch(service.apiURL + '/team/'+ team_id +'/absent' , request).then(
    response => service.handleResponse(response, true).then(
      () => {
        service.clearAlerts()
        return true;
      },
      error => {
        service.setError(error)
        return false;
      }
    )
  )
}
