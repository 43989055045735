import {service} from "./service";


export const teamService={
    fetchUserTeams,
    createTeam,
    joinTeam,
    leaveTeam,
    fetchTournaments,
    promoteToCaptain,
    kickPlayer,
    joinTeamDirect,
    createTeamLink,
    renameTeam,
    findById,
    setPlayerAbsent,
}

function fetchUserTeams() {
    service.setLoading();
    const request = {
        method: 'GET',
        headers: {
            'Authorization': service.getAuthorizationHeader()
        }
    };
    return fetch(service.apiURL + '/user/teams', request).then(

        response => service.handleResponse(response,false).then(
            teams => {
                service.clearAlerts();
                return teams;
            },
            error => {
                service.setError(error);
            }
        )
    );
}

function createTeam(team){

    const request = {
        method: 'POST',
        headers: {
            'Authorization': service.getAuthorizationHeader(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(team)
    };

    return fetch(service.apiURL + '/team', request).then(
        response => service.handleResponse(response,true)
    );
}

function joinTeam(teamJoin){

    const request = {
        method: 'POST',
        headers: {
            'Authorization': service.getAuthorizationHeader(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(teamJoin)
    };

    return fetch(service.apiURL + '/team/'+teamJoin.team_id+'/join', request).then(
        response => service.handleResponse(response,true).then(
        )
    );
}

function leaveTeam(team_id){
    service.setLoading();
    const request={
        method:'GET',
        headers:{
            'Authorization':service.getAuthorizationHeader()
        }
    };
    return fetch(service.apiURL+'/team/'+team_id+'/leave', request).then(
        response=> service.handleResponse(response,true).then(
            ()=>{
                service.clearAlerts()
            },
            error => {
                service.setError(error)
            }
        )
    )
}


function fetchTournaments(){
    service.setLoading();
    const request={
        method:'GET',
    };
    return fetch(service.apiURL+'/event/current', request).then(
        response=> service.handleResponse(response,false).then(
            event => {
                service.clearAlerts()
                return event
            },
            error => {
                service.setError(error)
            }
        )
    )
  }

function promoteToCaptain(teamId, userId) {
    service.setLoading();
    const request={
        method:'PATCH',
        headers:{
            'Authorization':service.getAuthorizationHeader()
        }
    };
    return fetch(service.apiURL+'/team/'+teamId+'/promote/'+userId, request).then(
        response=> service.handleResponse(response,true).then(
            teams => {
                service.clearAlerts()
                return teams
            },
            error => {
                service.setError(error)
            }
        )
    )
}

function kickPlayer(teamId, userId) {
    service.setLoading();
    const request={
        method:'GET',
        headers:{
            'Authorization':service.getAuthorizationHeader()
        }
    };
    return fetch(service.apiURL+'/team/'+teamId+'/kick/'+userId, request).then(
        response=> service.handleResponse(response,true).then(
            teams => {
                service.clearAlerts()
                return teams
            },
            error => {
                service.setError(error)
            }
        )
    )
}

function joinTeamDirect(name, code) {
    service.setLoading();

    const request={
        method:'POST',
        headers:{
            'Authorization':service.getAuthorizationHeader(),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(name),
    };
    return fetch(service.apiURL+'/team/join-direct?join_code='+code, request).then(
      response=> service.handleResponse(response,true)
    )
}

function createTeamLink(teamId) {
    service.setLoading();

    const request={
        method:'GET',
        headers:{
            'Authorization':service.getAuthorizationHeader(),
        },
    };
    return fetch(service.apiURL + '/team/' + teamId + '/create-link', request).then(
      response => service.handleResponse(response, true).then(
          data => {
            service.clearAlerts();
            service.setSuccess();
            return data;
          },
          error => service.setError(error)
        )
    )
}

function renameTeam(teamId, name) {
  service.setLoading();
  const request={
    method:'PATCH',
    headers:{
      'Authorization':service.getAuthorizationHeader(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(name)
  };
  return fetch(service.apiURL+'/team/'+teamId, request).then(
    response=> service.handleResponse(response,true)
  )
}

function findById(teamId) {
  service.setLoading();
  const request = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  };

  return fetch(service.apiURL + '/team/' + teamId, request).then(
    response => service.handleResponse(response, true).then(
      team => {
        service.clearAlerts()
        return team
      },
      error => {
        service.setError(error)
      }
    ));
}

function setPlayerAbsent(teamId, userId) {
  service.setLoading();
  const request = {
    method: 'PATCH',
    headers: {
      'Authorization':service.getAuthorizationHeader(),
      'Content-Type': 'application/json'
    },
  };

  return fetch(service.apiURL + '/team/'+ teamId+'/absent'+'/'+userId, request).then(
    response => service.handleResponse(response, true).then(
      () => {
        service.clearAlerts()
      },
      error => {
        service.setError(error)
      }
    ));
}
