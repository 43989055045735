import {userService} from "@/services";
import router from "@/router";
const user =JSON.parse(localStorage.getItem('user_token'));
const state = user? {status:{loggedIn:true},user:user}:{status: {},user:null};

const actions= {
    login({dispatch, commit}, {email, password}) {
        commit('loginRequest', {email});
        dispatch('alert/loading','',{root:true});
        userService.login(email, password).then(
            user => {
                commit('loginSuccess', user)
                dispatch('getUserData',user)
                router.push('/')
                setTimeout(()=>{
                    dispatch('alert/success','Login Successful',{root:true})
                })
            },
            error => {
                commit('loginFailure', error)
                dispatch('alert/error',error,{root:true})
            }
        )
    },
    logout({commit}) {
        router.push('/')
        userService.logout();

        commit('logout');
    },
    register({dispatch, commit}, user) {
        commit('registerRequest', user);
        dispatch('alert/loading','',{root:true});
        userService.register(user).then(
            user => {
                commit('registerSuccess',user);
                router.push('/register/success');
                setTimeout(()=>{
                    dispatch('alert/success','Registration Successful',{root:true})
                })
            },
            error=>{
                commit('registerFailure',error)
                dispatch('alert/error',error,{root:true})
            }
        )
    },

    update({dispatch, commit}, user) {
        commit('registerRequest', user);
        dispatch('alert/loading','',{root:true});
        userService.update(user).then(
            user => {
                commit('userUpdateSuccess',user);
                setTimeout(()=>{
                    dispatch('alert/success','Update Successful',{root:true})
                })
            },
            error=>{
                dispatch('alert/error',error,{root:true})
            }
        )
    },


    getUserData({dispatch,commit}) {
        dispatch('alert/loading','',{root:true});
        if(state.user!=null){
            return userService.getUserData().then(
                userdata=>{
                    commit('userFetchSuccess',userdata);
                    setTimeout(()=>{
                        dispatch('alert/clear','User Fetch Successful',{root:true})});
                    return userdata
                },
                error=>{
                    commit('userFetchFailure');
                    dispatch('alert/error',error,{root:true})
                    dispatch('logout');
                }
            )
        }

    },
}

const mutations={
    loginRequest(state){
        state.status={loggingIn:true}
        state.user=null
    },
    loginSuccess(state,user){
        state.status={loggedIn:true}
        state.user=user;
    },
    loginFailure(state){
        state.status={}
        state.user=null
    },
    logout(state){
        state.status={}
        state.user=null
    },
    registerRequest(state){
        state.status={registering:true};
    },
    registerSuccess(state){
        state.status={registered:true}
    },
    registerFailure(state){
        state.status={};
    },
    userFetchSuccess(state,userdata){

        state.user["userdata"]=userdata;
    },
    userFetchFailure(state){

        delete state.userdata;
    },
    userUpdateSuccess(state,userdata){
        state.user["userdata"]=userdata;
    },


}
export const account={
    namespaced:true,
    state,
    actions,
    mutations
}
