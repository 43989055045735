import{userService} from "./user.service";
import store from "../store/";

const apiURL=window['VUE_APP_API_HOST']||process.env.VUE_APP_API_HOST

export const service = {
    apiURL,
    handleResponse,
    getAuthorizationHeader,
    setLoading,
    clearAlerts,
    setError,
    setSuccess
}

function handleResponse(response, doLogout){
    return response.text().then(text=>{
        const data= text&&JSON.parse(text);
        if(!response.ok){
            if(response.status===401&&doLogout){
                userService.logout();
            }
            const error=(data&&data.message)|| data.detail
            return Promise.reject(error);
        }
        return data;
    })
}

function getAuthorizationHeader() {

    let token = store.state.account.user.access_token;
    let token_type = store.state.account.user.token_type;

    return token_type + ' ' + token

}
function setSuccess(success){
    store.dispatch("alert/success",success,{root:true})
}
function setLoading(){
    store.dispatch("alert/loading",{root:true})
}
function clearAlerts(){
    store.dispatch("alert/clear",{root:true})
}
function setError(error){
    store.dispatch("alert/error",error,{root:true})
}
